import { FormStore } from "./FormStore";
import {
  Checkbox,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { observer } from "mobx-react";


function _renderLabelWithLink() {
  return (
    <span>
      Accept{" "}
      <Link href="https://www.innofalls.com/terms-and-conditions/" target="_blank" underline>
        Terms and Conditions
      </Link> and <Link href="https://www.innofalls.com/privacy-statement/" target="_blank" underline>
        Privacy Agreement
      </Link> *
    </span>
  );
}

export const FormBottomRow = observer((props: {store: FormStore, submit: () => void}) => {


  return <Stack style={{ margin: "0 0 0 20px" }} horizontalAlign={"start"} tokens={{ childrenGap: 10 }}
                styles={{ root: { maxWidth: 640 } }}>


    <Checkbox styles={{root: {}}} ariaLabel={"Subscribe to Newsletter"} label="Subscribe to Newsletter" onChange={
      (e, v) => props.store.setNewsletter(v)} />

    <Checkbox ariaLabel={"Accept Terms of Use"} styles={{
      root: {},
      label: {
        color: props.store.acceptEulaError ? "rgb(164, 38, 44)" : "",
      },
    }} required label="Terms and Conditions" onRenderLabel={_renderLabelWithLink} onChange={
      (e, v) => props.store.setEula(v)} />


    {props.store.acceptEulaError &&
    <Text style={{ color: "rgb(164, 38, 44)" }} variant="tiny" block>
      {props.store.acceptEulaError}
    </Text>
    }

    <Text variant="smallPlus" block>
      By submitting your trial request, you agree to receive introductory e-mails helping you to get started with
      Innofalls.
    </Text>

    <Stack horizontal={true} verticalAlign="center" horizontalAlign={"end"} tokens={{ childrenGap: 20 }}>
      {props.store.submissionPending &&
      <Spinner size={SpinnerSize.medium} />}
      <PrimaryButton style={{ border: props.store.hasError ? "2px solid #ff0000" : undefined }}
                     disabled={props.store.submissionComplete || props.store.submissionPending} ariaLabel={"Submit"}
                     text="Request Trial" onClick={props.submit} />

      {props.store.hasError &&
      <Text style={{ color: "rgb(164, 38, 44)" }} variant="small" block>
        Some fields are missing! Please give it a second look.
      </Text>
      }
    </Stack>

    {props.store.serverError &&
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      onDismiss={_ => props.store.dismissServerError()}
      dismissButtonAriaLabel="Close"
    >
      {props.store.serverError}
    </MessageBar>
    }

  </Stack>;
});