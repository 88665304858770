import { makeAutoObservable } from "mobx";
import { IComboBoxOption } from "@fluentui/react";

export class FormStore {

  constructor() {
    makeAutoObservable(this);
  }

  submissionComplete: boolean = false;
  submissionPending: boolean = false;

  firstName: string = "";
  firstNameError: string = "";

  lastName: string = "";
  lastNameError: string = "";

  company: string = "";
  companyError: string = "";
  country: string = "";
  countryError: string = "";

  role: string = "";
  roleError: string = "";

  email: string = "";
  emailError: string = "";

  featureInterest: string[] = [];
  featureInterestError: boolean = false;

  reportIntent: string[] = [];
  reportIntentError: boolean = false;

  subscribeNewsletter: boolean = false;
  acceptEula: boolean = false;

  acceptEulaError: string = "";

  serverError: string = "";

  get hasError() {
    return !!(
      this.firstNameError
      || this.lastNameError
      || this.companyError
      || this.countryError
      || this.countryError
      || this.emailError
      || this.acceptEulaError
    )
  }

  validateContent(): boolean {
    this.serverError = "";
    this.firstName = this.firstName.trim();
    if (!this.firstName) {
      this.firstNameError = "Be polite, tell us your first name.";
    }
    this.lastName = this.lastName.trim();
    if (!this.lastName) {
      this.lastNameError = "Be polite, tell us your last name.";
    }
    this.company = this.company.trim();
    if (!this.company) {
      this.companyError = "Please let us know where you work.";
    }
    this.country = this.country.trim();
    if (!this.country) {
      this.countryError = "Please let us know where you live.";
    }
    this.email = this.email.trim();
    if (!this.email) {
      this.emailError = "Please let us know where you work.";
    }
    if (!this.acceptEula) {
      this.acceptEulaError = "Please accept our End User License Agreement in order to use Innofalls."
    }
    if (!this.role) {
      this.roleError = "Please give us a hint, what you are doing.";
    }
    if (!this.featureInterest.length) {
      this.featureInterestError = true;
    }
    if (!this.reportIntent.length) {
      this.reportIntentError = true;
    }
    const emailRegex =   /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const result = emailRegex.test(this.email);
    if (!result) {
      this.emailError = "Please provide a valid email address.";
    }
    return !this.hasError;
  }

  getPostData() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      country: this.country,
      role: this.role,
      email: this.email,
      subscribeNewsletter: this.subscribeNewsletter,
      featureInterest: this.featureInterest.join(", "),
      reportIntent: this.reportIntent.join(", "),
    }
  }

  setFirstName(v: string | undefined) {
    this.firstNameError = "";
    if (v) {
      this.firstName = v;
    }
  }
  setLastName(v: string | undefined) {
    this.lastNameError = "";
    if (v) {
      this.lastName = v;
    }
  }
  setCompany(v: string | undefined) {
    this.companyError = "";
    if (v) {
      this.company = v;
    }
  }
  setCountry(v: IComboBoxOption | undefined) {
    this.countryError = "";
    if (v) {
      this.country = v.text;
    } else {
      this.country = "";
    }
  }
  setRole(v: IComboBoxOption | undefined) {
    this.roleError = "";
    if (v) {
      this.role = v.text;
    } else {
      this.role = "";
    }
  }
  setEMail(v: string | undefined) {
    this.emailError = "";
    if (v) {
      this.email = v;
    }
  }

  toggleFeature(name: string, v: boolean | undefined) {
    this.featureInterestError = false;
    if (v) {
      if (!this.featureInterest.includes(name)) {
        this.featureInterest.push(name);
      }
    } else {
      this.featureInterest = this.featureInterest.filter(n => n !== name);
    }
  }

  toggleReport(name: string, v: boolean | undefined) {
    this.reportIntentError = false;
    if (v) {
      if (!this.reportIntent.includes(name)) {
        this.reportIntent.push(name);
      }
    } else {
      this.reportIntent = this.reportIntent.filter(n => n !== name);
    }
  }

  setNewsletter(v: boolean | undefined) {
    if (v !== undefined) {
      this.subscribeNewsletter = !!v;
    }
  }
  setEula(v: boolean | undefined) {
    this.acceptEulaError = "";
    if (v !== undefined) {
      this.acceptEula = !!v;
    }
  }

  dismissServerError() {
    this.serverError = "";
  }
  dismissEulaError() {
    this.acceptEulaError = "";
  }

}
