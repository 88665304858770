import { FormStore } from "./FormStore";
import { Checkbox, ComboBox, IStackProps, Stack } from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { observer } from "mobx-react";

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { width: 300 } },
};

const rowProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { maxWidth: 700 } },
};


export const FormMiddleRow = observer((props:{store: FormStore}) => {
  return <Stack wrap style={{ margin: 0 }} horizontal {...rowProps}>
    <Stack style={{ margin: 20 }} {...columnProps}>


      <ComboBox required
                defaultSelectedKey="" ariaLabel={"Describe your role in reporting"} tabIndex={6}
                label="What best describes your role in reporting?"
                errorMessage={props.store.roleError} options={[
        { key: "Receiver", text: "Report Receiver" },
        { key: "Developer", text: "Report Developer" },
        { key: "Consultant", text: "Consultant" },
        { key: "Executive", text: "Executive" },
      ]}
                onChange={(e, v) => props.store.setRole(v)}
                calloutProps={{ doNotLayer: true }}
      />

      <Stack tokens={{ childrenGap: 5 }}>
        <Text style={{fontWeight: 600}} variant={"medium"} block >
          What kind of features are you most interested in? *
        </Text>

        <Checkbox ariaLabel={"Specific Waterfall Types"} label="Specific Waterfall Types" onChange={
          (e, v) => props.store.toggleFeature("Specific Waterfall Types", v)} />
        <Checkbox ariaLabel={"Fast Waterfall Configuration"} label="Fast Waterfall Configuration" onChange={
          (e, v) => props.store.toggleFeature("Fast Waterfall Configuration", v)} />

        <Checkbox ariaLabel={"Consistently Formatted Reports"} label="Consistently Formatted Reports" onChange={
          (e, v) => props.store.toggleFeature("Consistently Formatted Reports", v)} />
        <Checkbox ariaLabel={"Explorative Features"} label="Explorative Features" onChange={
          (e, v) => props.store.toggleFeature("Explorative Features", v)} />
        <Checkbox ariaLabel={"Easy Comparisons"} label="Easy Comparisons" onChange={
          (e, v) => props.store.toggleFeature("Easy Comparisons", v)} />

        {props.store.featureInterestError &&
        <Text style={{ color: "rgb(164, 38, 44)" }} variant="small" block>
          Please pick at least one!
        </Text>
        }

      </Stack>

    </Stack>
    <Stack style={{ margin: 20 }} {...columnProps}>


      {/*<ComboBox required
                defaultSelectedKey="" ariaLabel={"Describe your intended use of Innofalls"} tabIndex={6}
                label="Which keyword strikes your intended use the most?"
                errorMessage={props.store.roleError} options={[
        { key: "Comparative", text: "Comparative" },
        { key: "Explorative", text: "Explorative" },
        { key: "Static", text: "Static" },
      ]}
                onChange={(e, v) => props.store.setRole(v)}
                calloutProps={{ doNotLayer: true }}
      />*/}

      <Stack tokens={{ childrenGap: 5 }}>
        <Text style={{fontWeight: 600}} variant={"medium"} block >
          Can you tell us, what kind of reports you will be testing Innofalls with? *
        </Text>

        <Checkbox ariaLabel={"Financial Report"} label="Financial Report" onChange={
          (e, v) => props.store.toggleReport("Financial Report", v)} />
        <Checkbox ariaLabel={"Operational Report"} label="Operational Report" onChange={
          (e, v) => props.store.toggleReport("Operational Report", v)} />

        <Checkbox ariaLabel={"Performance Report"} label="Performance Report" onChange={
          (e, v) => props.store.toggleReport("Performance Report", v)} />
        <Checkbox ariaLabel={"Explorative Dashboard"} label="Explorative Dashboard" onChange={
          (e, v) => props.store.toggleReport("Explorative Dashboard", v)} />
        <Checkbox ariaLabel={"Self-Service Application"} label="Self-Service Application" onChange={
          (e, v) => props.store.toggleReport("Self-Service Application", v)} />
        <Checkbox ariaLabel={"Broadcasting Template"} label="Broadcasting Template" onChange={
          (e, v) => props.store.toggleReport("Broadcasting Template", v)} />
        <Checkbox ariaLabel={"Printing Template"} label="Printing Template" onChange={
          (e, v) => props.store.toggleReport("Printing Template", v)} />
        <Checkbox ariaLabel={"Other"} label="Other" onChange={
          (e, v) => props.store.toggleReport("Other", v)} />

        {props.store.reportIntentError &&
        <Text style={{ color: "rgb(164, 38, 44)" }} variant="small" block>
          Please just give us a guess!
        </Text>
        }
      </Stack>



    </Stack>
  </Stack>
});