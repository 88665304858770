import React from "react";
import { observer } from "mobx-react";
import { FormStore } from "./FormStore";
import { SuccessModal } from "./SuccessModal";
import { Text } from "@fluentui/react/lib/Text";
import { FormBottomRow } from "./FormBottomRow";
import { FormUpperRow } from "./FormUpperRow";
import { FormMiddleRow } from "./FormMiddleRow";

export const TrialForm = observer((props: { store: FormStore }) => {



  const submit = async () => {
    console.log("submit");
    const resultOk = props.store.validateContent();
    if (resultOk) {
      props.store.submissionPending = true;
      const result = await fetch("/forms/submitTrialRequest", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(props.store.getPostData()),
      });
      props.store.submissionPending = false;
      if (!result.ok) {
        try {
          const text: { message: string[] } = JSON.parse(await result.text()) as any;
          if (text && text.message && text.message[0]) {
            props.store.serverError = "Please check your input: " + text.message[0];
          }
        } catch (e) {
          props.store.serverError = "Please check your input!";
        }
      } else {
        // OK
        props.store.submissionComplete = true;
      }
    }
  };

  return <React.Fragment>
    
    <FormUpperRow store={props.store} />
    <Text style={{
      display: "inline-block",
      maxWidth: 650,
      margin: "0 0 0 20px",
      padding: "20px 0px 0 0",
      borderTop: "1px solid lightgrey",
    }} variant={"medium"}>We'd like to learn, what you intend to use Innofalls for. We strive to improve Innofalls to your needs. <br/><i>Help us to become better!</i></Text>
    <FormMiddleRow store={props.store} />

    <div style={{
      maxWidth: 650,
      margin: "0 0 0 20px",
      padding: "20px 0px 0 0",
      borderTop: "1px solid lightgrey",
    }} > </div>

    <FormBottomRow store={props.store} submit={submit} />

    {props.store.submissionComplete &&
    <SuccessModal store={props.store} />
    }
  </React.Fragment>;
});
