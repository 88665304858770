import { observer } from "mobx-react";
import { FormStore } from "./FormStore";
import { FontWeights, getTheme, Link, mergeStyleSets, Modal } from "@fluentui/react";
import React from "react";


export const SuccessModal = observer((props: {store: FormStore}) => {
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  return <Modal
    titleAriaId={"Trial Request successful!"}
    isOpen={true}
    isBlocking={true}
  >
    <div className={contentStyles.header}>
      <span>Great! We received your request!</span>
    </div>
    <div className={contentStyles.body}>
      <p>
        We successfully received your trial request. Thank you for your interest in Innofalls. You will receive an e-mail
        with your trial license key shortly. It will enable you to use Innofalls without limitations for 30 days.
      </p>
      <p>
        In the meantime, we encourage you to have a look at the getting started page in our documentation:
      </p>
      <Link href="https://docs.innofalls.com/getting-started/create-your-first-waterfall" target={"_blank"}>Getting started</Link>
    </div>
  </Modal>
});
