import React from "react";
import "./App.css";
import { TrialForm } from "./TrialForm";
import { FormStore } from "./FormStore";
import { initializeIcons } from "@fluentui/react";


const formStore = new FormStore();

initializeIcons();

export const App: React.FunctionComponent = () => {
  return (
    <TrialForm store={formStore} />
  );
};
