import { FormStore } from "./FormStore";
import { ComboBox, IComboBoxOption, IStackProps, Stack, TextField } from "@fluentui/react";
import React from "react";
import { Countries } from "./countries";
import { observer } from "mobx-react";

const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10 },
  styles: { root: { width: 300 } },
};

const rowProps: Partial<IStackProps> = {
  tokens: { childrenGap: 0 },
  styles: { root: { maxWidth: 700 } },
};

const getCountries = (): IComboBoxOption[] => {
  return Countries.map(c => {
    return { key: c.name, text: c.name };
  });
};

export const FormUpperRow = observer((props:{store: FormStore}) => {
  return <Stack wrap style={{ margin: 0 }} horizontal {...rowProps}>
    <Stack style={{ margin: 20 }} {...columnProps}>


      <TextField ariaLabel={"First name"} tabIndex={1} label="First Name" errorMessage={props.store.firstNameError}
                 required
                 onChange={
                   (e, v) => props.store.setFirstName(v)} />

      <TextField ariaLabel={"Email"} tabIndex={3} label="Business E-Mail"
                 description="Your license key will be delivered to this address"
                 errorMessage={props.store.emailError}
                 required onChange={
        (e, v) => props.store.setEMail(v)} />


    </Stack>
    <Stack style={{ margin: 20 }} {...columnProps}>

      <TextField ariaLabel={"Last name"} tabIndex={2} label="Last Name" errorMessage={props.store.lastNameError}
                 required onChange={
        (e, v) => props.store.setLastName(v)} />


      <TextField ariaLabel={"Company"} tabIndex={4} label="Company" errorMessage={props.store.companyError} required
                 onChange={
                   (e, v) => props.store.setCompany(v)} />


      <ComboBox required
                defaultSelectedKey="" ariaLabel={"Country"} tabIndex={6} label="Country"
                errorMessage={props.store.countryError} options={getCountries()}
                onChange={(e, v) => props.store.setCountry(v)}
                calloutProps={{ doNotLayer: true }}
      />

    </Stack>
  </Stack>
});